import { useGetPrimaryPaymentSourceQuery } from "services/graphql";

export function usePrimaryPaymentSource() {
  const { data, loading, error, refetch } = useGetPrimaryPaymentSourceQuery();

  if (loading) {
    return {};
  }

  if (!data) {
    return {};
  }

  // @TODO?: Throw some kind of error
  if (error) {
    return {};
  }
  const { me } = data;

  if (!me) {
    return {};
  }

  const { primaryPaymentSource } = me;

  return {
    primaryPaymentSource,
    refetchPrimaryPaymentSource: refetch,
    loadingPrimaryPaymentSource: loading,
  };
}
