import React, { useState } from "react";
import { Token, loadStripe } from "@stripe/stripe-js";
import env from "helpers/env";
import { Elements as ElementsProvider } from "@stripe/react-stripe-js";
import { GatewayProvider, PaymentMethodType } from "services/graphql";
import SpanLink from "app/components/SpanLink";
import zendesk from "helpers/zendesk";
import Button from "app/components/Button";
import Alert from "app/components/Alert";
import PoweredByStripeIcon from "app/components/PoweredByStripeIcon";
import StripeCard from "app/components/StripeCard";
import PaypalButton from "app/components/PaypalButton";
import { useUserUpdatePaymentSource } from "app/routes/Account/Subscription/hooks";
import styles from "./styles.module.scss";

const stripePromise = loadStripe(env("PUBLIC_STRIPE_PUBLISHABLE_KEY"));

export function UpdatePaymentMethod({
  onSuccess,
  onCancel,
}: {
  onSuccess(): void;
  onCancel(): void;
}) {
  const [updateCardError, setUpdateCardError] = useState(null);

  const {
    userUpdatePaymentSource,
    userUpdatePaymentSourceResults,
  } = useUserUpdatePaymentSource({
    onError: () => {
      setUpdateCardError(true);
    },
    onCompleted: () => {
      onSuccess();
    },
  });

  const handleUpdateStripePayment = (token: Token) => {
    const options = {
      token: token.id,
      gatewayProvider: GatewayProvider.Stripe,
      type: PaymentMethodType.Card,
    };

    userUpdatePaymentSource({
      variables: options,
    });
  };

  const handleUpdateBraintreePayment = (nonce: any) => {
    const options = {
      token: nonce,
      gatewayProvider: GatewayProvider.Braintree,
      type: PaymentMethodType.PaypalExpressCheckout,
    };

    userUpdatePaymentSource({
      variables: options,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <ElementsProvider stripe={stripePromise}>
        <div className={styles.creditCardWrapper}>
          <h3>Credit/Debit</h3>
          <PoweredByStripeIcon />
        </div>
        <StripeCard
          onError={err => setUpdateCardError(err)}
          onSubmit={handleUpdateStripePayment}
          buttonMessage="Update Card"
          parentLoading={userUpdatePaymentSourceResults.loading}
        />
      </ElementsProvider>
      <div style={{ display: "flex" }}>
        <PaypalButton
          onSubmit={handleUpdateBraintreePayment}
          onError={setUpdateCardError}
        />
      </div>
      <Button
        type="button"
        className={styles.cancelButton}
        disabled={userUpdatePaymentSourceResults.loading}
        onClick={() => onCancel()}
      >
        Cancel
      </Button>
      {updateCardError && (
        <Alert variant="danger" closeAlert={() => setUpdateCardError(null)}>
          Unable to update payment method. Please try again or
          <SpanLink onClick={() => zendesk("webWidget", "open")}>
            {" "}
            contact us for help!
          </SpanLink>
        </Alert>
      )}
    </div>
  );
}
