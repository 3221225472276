import {
  useCheckout,
  useIPCountryName,
  useIPRegionName,
} from "modules/selectors";
import {
  SubscriptionProvider,
  useSubscriptionCreateForCustomerMutation,
} from "services/graphql";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { GET_SUBSCRIPTION_DATA } from "modules/user/actions-types";
import { CheckoutError } from "constants/cart";
import { orderCompleted } from "services/typewriter/segment";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import { usePlans } from "./usePlans";

interface Props {
  onSuccess?(): void;
}

export default function useSubscriptionCreateForCustomer(props?: Props) {
  const { onSuccess } = props || {};
  const checkout = useCheckout();
  const { selectedPlan, coupon } = checkout;
  const history = useHistory();
  const dispatch = useDispatch();
  const { refetch: refetchPlans } = usePlans();
  const { refetchPrimarySubscription } = usePrimarySubscription();

  const [
    execSusbcriptionCreateForCustomer,
    subscriptionCreateForCustomerResults,
  ] = useSubscriptionCreateForCustomerMutation();
  const countryName = useIPCountryName();
  const regionName = useIPRegionName();

  const subscriptionCreateForCustomer = async () => {
    return execSusbcriptionCreateForCustomer({
      variables: {
        planInfo: {
          id: selectedPlan.provider.id,
          name: SubscriptionProvider.Chargebee,
        },
        couponIds: coupon ? [coupon] : null,
        ipInfo: {
          countryName,
          regionName,
        },
      },
      onCompleted: ({
        subscriptionCreateForCustomer: subscriptionCreateData,
      }) => {
        dispatch({
          type: GET_SUBSCRIPTION_DATA,
          results: {
            subscription: subscriptionCreateData,
            isActive: subscriptionCreateData?.user?.isActive,
            hasTrialed: subscriptionCreateData?.user?.hasTrialed,
          },
        });

        const plan = subscriptionCreateData?.plan;

        if (plan) {
          orderCompleted({
            order_id: subscriptionCreateData.provider.id,
            coupon,
            products: [
              {
                product_id: plan.provider.id,
                sku: plan.provider.id,
                price: plan.priceInCents / 100,
                quantity: 1,
                name: plan.provider.id,
              },
            ],
          });
        }

        refetchPrimarySubscription();
        // Refetch plans to get new invoice estimates if user wants to switch or upgrade
        refetchPlans();

        if (onSuccess) {
          onSuccess();
        } else {
          history.push("/dashboard");
        }
      },
      onError: () => {
        throw new Error(CheckoutError.Generic);
      },
    });
  };

  return {
    subscriptionCreateForCustomer,
    subscriptionCreateForCustomerResults,
  };
}
